let sectionJSWrapper = document.querySelectorAll('.-js-wrapper');
sectionJSWrapper.forEach((section, i) => {
  let dataSectionType = section.getAttribute('section-type');
    let sectionClass = $(`.${dataSectionType}`);
    if(sectionClass) {
        $(section).appendTo(sectionClass).css('display', 'block')
    }
});
if (window.innerWidth < 760) {
    let stickyRoomRow = document.querySelector('.sidebar__row--sticky');
    if (stickyRoomRow) {
        stickyRoomRow.removeAttribute('data-scroll-sticky')
        stickyRoomRow.removeAttribute('data-scroll-target')
    }

}
const body = document.querySelector('body'),
    mainHeader = document.querySelector('.main-header'),
    hamburger = document.querySelector('.hamburger'),
    mobileNav = document.querySelector('.mobile-nav');
let body_classes = $('body').attr('class').split(/\s+/),
    lightgallery = document.querySelectorAll('.lightgallery');

function containClass(el, className) {
    return el.classList.contains(className)
}


hamburger.addEventListener('click', function() {
    if (this.classList.contains('is-active')) {
        this.classList.remove('is-active')
        mobileNav.classList.remove('active')
    } else {
        this.classList.add('is-active')
        mobileNav.classList.add('active')
    }
})
if(lightgallery) {

    lightgallery.forEach((item, i) => {
        if (item.classList.contains('swiper-wrapper')) {
            lightGallery(item, {
                download: false,
                counter: true,
                hash: true,
                selector: '.swiper-slide:not(.swiper-slide-duplicate) .swiper-slide__media'
            });
        } else {
            lightGallery(item, {
                download: false,
                counter: true,
                hash: true,
                selector: '.section-gallery__media'
            });
        }
    });
}


(function() {
    function logElementEvent(eventName, element) {
        console.log(Date.now(), eventName, element.getAttribute("data-src"));
    }

    var callback_enter = function(element) {
        //logElementEvent("🔑 ENTERED", element);
    };
    var callback_exit = function(element) {
        //logElementEvent("🚪 EXITED", element);
    };
    var callback_loading = function(element) {
        //logElementEvent("⌚ LOADING", element);
    };
    var callback_loaded = function(element) {
        //logElementEvent("👍 LOADED", element);
    };
    var callback_error = function(element) {
        //logElementEvent("💀 ERROR", element);
        element.src =
            "https://via.placeholder.com/440x560/?text=Error+Placeholder";
    };
    var callback_cancel = function(element) {
        //logElementEvent("🔥 CANCEL", element);
    };
    var callback_finish = function() {
        //logElementEvent("✔️ FINISHED", document.documentElement);
    };

    ll = new LazyLoad({
        // Assign the callbacks defined above
        callback_enter: callback_enter,
        callback_exit: callback_exit,
        callback_cancel: callback_cancel,
        callback_loading: callback_loading,
        callback_loaded: callback_loaded,
        callback_error: callback_error,
        callback_finish: callback_finish
    });
})();

if (!containClass(body, 'body-offer') && !containClass(body, 'body-pluginident-HotelSystemsBooking') && !containClass(body, 'body-pluginident-Vouchery')) {

    const scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        tablet: {
            smooth: false,
        },
        mobile: {
            smooth: false
        }
    });
    let stopScroll = document.querySelectorAll('.stop-scroll');
    const scrollDown = document.querySelector('.scroll-down');
    const target = document.querySelector('[data-scroll-section-id="section2"]');
    if ( ! containClass(body, 'body-pluginident-PakietyKategorie')) {
        scroll.on('scroll', (position) => {
            if ((position.scroll.y) > 50) {
                mainHeader.classList.add('active')
            } else {
                mainHeader.classList.remove('active')
            }
        });
    } else {
        mainHeader.classList.add('active')
    }
    if(stopScroll) {
        stopScroll.forEach((item) => {
            item.onmouseover = function() {
                scroll.stop()
            }
            item.onmouseout = function() {
                scroll.start()
            }
        });
    }

    if (scrollDown) {
        scrollDown.addEventListener('click', function(el) {
            scroll.scrollTo(target, {
                offset: -41
            })
        })
    }

    if (scroll) {
        scroll.update();
    }
} else {
    if ( ! containClass(body, 'body-pluginident-HotelSystemsBooking')) {
        window.addEventListener('scroll', function(ev) {
            if (this.scrollY > 30) {
                mainHeader.classList.add('active')
            } else {
                mainHeader.classList.remove('active')
            }
        })
    } else {
        mainHeader.classList.add('active')
    }
    body.classList.add('locomotive-off')
}
setTimeout(function() {
    $('.preloader').fadeOut();
}, 500)
